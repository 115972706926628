import './Projects.styl'
import DomComponent from 'abstractions/DomComponent'
import store from 'controllers/store'

export default class Projects extends DomComponent {
  didMount () {
    if (!store.get('device.desktop')) return
    this.bindFuncs(['mouseover', 'mouseout'])
    this.items = [].slice.call(this.refs.base.querySelectorAll('.project'))
    this.addRef('projectLink', document.querySelector('header.site-nav .nav-item--projects a'))
    this.initialLinkText = this.refs.projectLink.textContent
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].addEventListener('mouseover', this.mouseover)
      this.items[i].addEventListener('mouseout', this.mouseout)
    }
  }

  mouseover (e) {
    e.stopPropagation()
    if (store.get('size').w < store.get('mq.mobile')) return
    for (let i = 0; i < e.path.length; i++) {
      if (!e.path[i].dataset || !e.path[i].dataset.projectName) continue
      this.refs.projectLink.textContent = e.path[i].dataset.projectName
      this.refs.projectLink.parentNode.classList.remove('active')
      break
    }
  }

  mouseout (e) {
    e.stopPropagation()
    this.refs.projectLink.textContent = this.initialLinkText
    this.refs.projectLink.parentNode.classList.add('active')
  }

  willUnmount () {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].removeEventListener('mouseover', this.mouseover)
      this.items[i].removeEventListener('mouseout', this.mouseout)
    }
    this.items = []
  }
}
